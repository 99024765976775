import { useState } from "react";
import LoopService from "../components/LoopService";
import ServicesGrid from "../components/ServicesGrid";
import Spacer from "../components/Spacer";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";

export default function Services() {
  const navigate = useNavigate();
  const [services, setServices] = useState([
    {
      title: "Websites",
      description:
        "Acquire a strong image on the web for your customers to know what your business is about.",
    },
    {
      title: "Eshops",
      description:
        "Sell your products and services directly from the web. Calculate any costs in behalf of the customer online and sync your company's data for efficiency.",
    },
    {
      title: "Applications",
      description:
        "Reach the mobile phones of your customers by letting us build a top-notch and efficient mobile application for your business.",
    },
    {
      title: "Automated Tools",
      description:
        "Automate digital actions of your company by using our well-structured and thoroughtly tested systems.",
    },
    {
      title: "Marketing Strategy",
      description:
        "Reach out to the vast and ever-growing customer base of the Internet, using our Marketing Strategies.",
    },
  ]);

  return (
    <div className="services page">
      <header>
        <h1>How can I assist you?</h1>
      </header>
      <Spacer height="40px" />
      <ServicesGrid items={services} />
      <Spacer height="40px" />
      <Button onClicked={() => navigate("/contact")} text="Plan your project" />
      <Spacer height="20px" />
    </div>
  );
}
