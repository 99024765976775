import { Link } from "react-router-dom";

export default function LoopProject({
  link = "#",
  image = null,
  title = null,
  description = null,
  viewDetailsLink = null,
  categories = [],
  currentFilter = "",
  comingSoon = false,
}) {
  return (
    <div
      className={`loop-project ${
        currentFilter !== "" && !categories.includes(currentFilter)
          ? "hidden"
          : ""
      }`}
    >
      <a href={link} target="_blank" rel="nooopener noreferrer">
        {image && <img src={image} alt={title} />}
        {title && <h2>{title}</h2>}
        {comingSoon && <h3 className="coming-soon">Coming soon</h3>}
        {description && <h3>{description}</h3>}
      </a>
      {viewDetailsLink && <Link to={viewDetailsLink}>View details</Link>}
    </div>
  );
}
