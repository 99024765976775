export default function TextWithImage({ text, image, reverse = false }) {
  return (
    <div className={`text-with-image ${reverse ? "reverse" : ""}`}>
      <div className="text-with-image-text">{text}</div>
      <div className="text-with-image-image">
        <img src={image} />
      </div>
    </div>
  );
}
