import LoopProject from "./LoopProject";

export default function ProjectsGrid({ items = [], currentFilter = "" }) {
  return (
    items.length > 0 && (
      <div className="projects-grid">
        {items.map((item) => {
          return (
            <LoopProject
              title={item.title}
              description={item.description}
              link={item.link}
              viewDetailsLink={item.viewDetailsLink}
              categories={item.categories}
              currentFilter={currentFilter}
              comingSoon={item.comingSoon}
            />
          );
        })}
      </div>
    )
  );
}
