import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Spacer from "../components/Spacer";
import TextWithImage from "../components/TextWithImage";

export default function Home() {
  const navigate = useNavigate();

  return (
    <div className="home page">
      <header>
        <h1>Anastasios Bolkas</h1>
        <Spacer height="10px" />
        <h2>Software Engineer</h2>
      </header>
      <Spacer height="40px" />
      <TextWithImage
        text={
          <>
            <h2>Who I Am</h2>
            <p>
              To reach the point of crafting fully customized projects, I had to
              start small.
              <br />
              Starting back in 2019 as a junior web developer, I was building
              simple websites.
              <br />
              Fast-forward 2.5 years later, working at a company and practicing
              my skills lead to me designing and crafting more customized
              solutions. That is when I built my first fully custom e-shop with
              a Loyalty program connected to it.
              <br /> Now, after 4 years of constant learning, practicing and
              working, I am able to completely engineer the architecture of a
              website, eshop, web and mobile application - or an admin panel -
              and create it from scratch, while also connecting them with each
              other.
            </p>
          </>
        }
        image={"/programmer.svg"}
      />
      <Spacer height="80px" />
      <TextWithImage
        reverse={true}
        text={
          <>
            <h2>Creativity Leads to New Ways</h2>
            <p>
              As a creative person, I know no boundaries. That is why I have
              decided to take things to the next level and move on by starting
              my own business, aiming to provide Online Solutions to companies.
            </p>
          </>
        }
        image={"./creative-work.svg"}
      />
      <Spacer height="80px" />
      <TextWithImage
        text={
          <>
            <h2>Everything is Possible with Team Work</h2>
            <p>
              A well-done job can only be achieved through a combination of
              skills and knowledge. That is why I am always backed up by my
              team. We are 5 people proficient in different subjects - web
              development, mobile development, web design, marketing and social
              media.
            </p>
          </>
        }
        image={"/people.svg"}
      />
      <Spacer height="80px" />
      <p style={{ textAlign: "center" }}>
        Together, we can build anything your business needs to benefit from the
        Internet's market.
      </p>
      <Spacer height="20px" />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClicked={() => navigate("/contact")}
          text="Plan your project"
        />
      </div>
      <Spacer height="20px" />
    </div>
  );
}
